/**
 * The rr twitter icon
 * @returns SVG
 */
export default function svgTwitter(): JSX.Element {
    return (
        <>
            <svg height="30" viewBox="0 0 36.923 30" width="36.923" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m36.923 51.552a15.782 15.782 0 0 1 -4.362 1.2 7.527 7.527 0 0 0 3.33-4.184 15.127 15.127 0 0 1 -4.8 1.832 7.569 7.569 0 0 0 -13.091 5.172 7.794 7.794 0 0 0 .175 1.726 21.426 21.426 0 0 1 -15.6-7.918 7.572 7.572 0 0 0 2.325 10.12 7.475 7.475 0 0 1 -3.42-.932v.083a7.6 7.6 0 0 0 6.065 7.438 7.555 7.555 0 0 1 -1.985.249 6.693 6.693 0 0 1 -1.433-.129 7.642 7.642 0 0 0 7.073 5.269 15.21 15.21 0 0 1 -9.385 3.228 14.177 14.177 0 0 1 -1.815-.106 21.31 21.31 0 0 0 11.612 3.4c13.929 0 21.545-11.538 21.545-21.54 0-.335-.012-.658-.028-.978a15.1 15.1 0 0 0 3.794-3.93z"
                    transform="translate(0 -48)"
                />
            </svg>
        </>
    );
}
