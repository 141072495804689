// When Hamburger Menu opened, this handler function allows click outside the menu to close it.
import { useEffect } from 'react';

export const useOnClickOutside = (ref: React.RefObject<HTMLElement>, handler: (event) => void): void => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};
