export const calculateSavings = (discountedPrice: string, regularPrice: string): number => {
    // Convert price strings to numerical values
    const discountedValue = parseFloat(discountedPrice.slice(1));
    const regularValue = parseFloat(regularPrice.slice(1));

    // Calculate savings
    let savings = 100 - (100 * discountedValue) / regularValue;

    // Round savings to the nearest integer
    savings = Math.round(savings);

    return savings;
};
