/**
 * The Lifetime Discount Check Mark
 * @returns SVG
 */
export default function svgCheckmark(): JSX.Element {
    return (
        <>
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1.5L6 12.5L1 7.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
