/**
 * The rr instagram icon
 * @returns SVG
 */
export default function svgInstagram(): JSX.Element {
    return (
        <>
            <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="m20.625 0h-11.25a9.376 9.376 0 0 0 -9.375 9.375v11.25a9.376 9.376 0 0 0 9.375 9.375h11.25a9.376 9.376 0 0 0 9.375-9.375v-11.25a9.376 9.376 0 0 0 -9.375-9.375zm6.563 20.625a6.57 6.57 0 0 1 -6.563 6.563h-11.25a6.57 6.57 0 0 1 -6.563-6.563v-11.25a6.57 6.57 0 0 1 6.563-6.562h11.25a6.57 6.57 0 0 1 6.563 6.563z" />
                    <path
                        d="m135.5 128a7.5 7.5 0 1 0 7.5 7.5 7.5 7.5 0 0 0 -7.5-7.5zm0 12.188a4.688 4.688 0 1 1 4.688-4.688 4.694 4.694 0 0 1 -4.688 4.688z"
                        transform="translate(-120.5 -120.5)"
                    />
                    <circle cx="23.062" cy="6.937" r=".999" />
                </g>
            </svg>
        </>
    );
}
