export const formatAlgoliaResults = (item) => {
    return {
        categoryItemProps: {
            id: Number(item.primaryCategoryId),
            name: item.primaryCategoryName,
            slug: String(item.primaryCategorySlug)
        },
        secondaryCategoryItemProps: {
            name: item.secondaryCategoryName,
            slug: String(item.secondaryCategorySlug) || ''
        },
        tertiaryCategoryItemProps: {
            name: item.tertiaryCategoryName,
            slug: String(item.tertiaryCategorySlug) || ''
        },
        priceItemProps: {
            isFlash: Boolean(item.isFlash),
            isLimitedTrial: false,
            isSpecial: false,
            isTrial: false,
            length: Number(item.primaryLength),
            lifetimeDiscount: Boolean(item.lifetimeDiscount),
            price: item.price,
            pricePerUnit: '0.00',
            primaryTrialIsLimited: true,
            primaryTrialPrice: '0.00',
            rebillPrice: '0.00',
            regularPrice: String(item.primaryRegularPrice),
            savings: Number(item.primarySavings),
            unit: String(item.unitType)
        },
        siteItemProps: {
            catListDescription: String(item.catListDescription),
            hasFlash: Boolean(item.isFlash),
            id: Number(item.objectID),
            isActive: Boolean(item.isActive),
            isTopSite: Boolean(item.isTopSite),
            name: String(item.name),
            promoLabel: '',
            slug: String(item.reviewSlug),
            thumb: String(item.thumb450),
            thumbID: item.thumbId,
            totalScore: Number(item.score),
            content: String(item.content),
            metaDescription: String(item.metaDescription),
            tags: item.tags || [],
            reviewDate: new Date(item.reviewDate).toISOString(),
            downloadsIncluded: Boolean(item.downloadsIncluded),
            moneyBackGuaranteed: Boolean(item.moneyBackGuaranteed),
            isGay: Boolean(item.isGay)
        }
    };
};
