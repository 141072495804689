import { useRouter } from 'next/router';
import aa from 'search-insights';

interface props {
    path: string;
    hasFlash: boolean;
    savings: number;
    openSiteCategory: string;
    openSitePath: string;
    openSiteSite?: number;
    eventAction?: string;
    qaID?: string;
    textButton?: string;
    style?: string;
    createAlgoliaEvent?: any;
    relCategory?: string;
}

export default function VisitSiteCTA({
    path,
    hasFlash,
    savings,
    openSiteCategory = 'Opensite',
    openSitePath,
    openSiteSite,
    qaID,
    style,
    eventAction = 'Opensite',
    textButton,
    createAlgoliaEvent,
    relCategory = 'noopener noreferrer nofollow'
}: props): JSX.Element {
    const router = useRouter();
    //this is sending the clickedObjectIDs or convertedObjectIDs to Algolia
    //@param eventName name to describe where the event is being clicked on ex. 'Similar Sites View Site'
    function reviewAlgoliaEvent(eventName: string) {
        aa('init', {
            appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
            apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
            useCookie: true, // since v2, this is false by default
            cookieDuration: 60 * 60 * 1000 // one hour, in milliseconds (default: 15552000000)
        });
        const algoliaString = sessionStorage.getItem('algoliaQuery');
        const algoliaData = algoliaString ? JSON.parse(algoliaString) : '';
        if (algoliaData && algoliaData.reviewSlug === openSiteSite) {
            aa('convertedObjectIDsAfterSearch', {
                index: 'RRX',
                eventName: String(`${eventName}${router && router.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${openSiteSite}`],
                queryID: algoliaData.queryID
            });
        } else {
            aa('convertedObjectIDs', {
                index: 'RRX',
                eventName: String(`${eventName}${router && router.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${openSiteSite}`]
            });
            sessionStorage.removeItem('algoliaQuery');
        }
    }
    return (
        <>
            {hasFlash === true ? (
                <a
                    href={path}
                    onClick={() =>
                        createAlgoliaEvent
                            ? createAlgoliaEvent('conversion', 'Card Visit Site')
                            : reviewAlgoliaEvent('Review CTA')
                    }
                    title={'Redeem Now'}
                    data-event-category={openSiteCategory}
                    data-opensite-path={openSitePath}
                    data-event-label={openSiteSite}
                    data-event-action={eventAction}
                    className={`redeemNowCTA button ${style ? style : ''}`}
                    target="_blank"
                    rel={relCategory}
                    id={qaID}>
                    {'REDEEM NOW'}
                </a>
            ) : (
                <a
                    href={path}
                    onClick={() =>
                        createAlgoliaEvent
                            ? createAlgoliaEvent('conversion', 'Card Visit Site')
                            : reviewAlgoliaEvent('Review CTA')
                    }
                    className={style ? `viewDiscountCTA button` : `redeemNowCTA button`}
                    title={'Visit Site'}
                    data-event-category={openSiteCategory}
                    data-opensite-path={openSitePath}
                    data-event-label={openSiteSite}
                    data-event-action={eventAction}
                    target="_blank"
                    rel={relCategory}
                    id={qaID}>
                    {textButton ? textButton : savings > 0 ? 'VIEW DISCOUNT' : 'VISIT SITE'}
                </a>
            )}
        </>
    );
}
