/**
 * The large bunny logo displayed on the splash page
 * @returns SVG
 */
import styles from '@styles/common-scss/navbar/_navbar.module.scss';

export default function svgNavLogoWhite(): JSX.Element {
    return (
        <>
            <svg
                className={styles.navLogo}
                xmlns="http://www.w3.org/2000/svg"
                width="148"
                height="42"
                viewBox="0 0 148 42"
                fill="none">
                <path d="M0 1.05127H40.1604V41.0119H0V1.05127Z" fill="#009C06" />
                <path
                    d="M15.1917 10.8266C14.556 8.67597 13.9203 6.27228 12.5217 5.00717C12.1744 4.67072 11.7288 4.45267 11.2489 4.38446C10.7691 4.31625 10.2799 4.4014 9.85183 4.62764C7.43616 6.01925 8.07184 10.0676 8.83468 12.8508C9.64725 15.4765 10.7116 18.0184 12.0132 20.4414C8.96182 23.3512 7.18185 27.526 6.92757 33.3455C7.79933 32.184 9.06369 31.3752 10.4875 31.0683C13.5389 30.6888 15.0646 33.219 16.8445 34.3576C17.0864 33.6194 17.3839 32.9005 17.7345 32.2069C18.3702 32.3334 19.0059 32.3334 19.5145 32.4599L18.7517 34.7371C18.6245 34.9901 18.3702 35.2431 18.7517 35.3696C19.1331 35.4961 19.0059 35.1166 19.1331 34.8636C19.5145 34.1045 19.7688 33.3455 20.0231 32.7129C20.6588 32.8394 21.2944 32.8394 21.803 32.9659C21.4216 34.1045 21.0402 35.1166 20.6588 36.1287C26.8886 37.6468 32.4828 34.7371 32.3556 28.7911C32.3556 24.9958 30.1943 22.7186 28.16 20.5679C30.3238 17.7027 32.1576 14.6049 33.627 11.3327C34.2473 10.1141 34.5944 8.77598 34.6442 7.41087C34.6687 7.02805 34.6088 6.64454 34.4686 6.28719C34.3285 5.92984 34.1115 5.6073 33.833 5.34215C33.5544 5.07701 33.2209 4.87567 32.8559 4.75225C32.4909 4.62882 32.1032 4.58629 31.7199 4.62764C29.94 4.75415 28.9229 6.27228 28.0329 7.53738C25.9986 10.5736 24.7272 14.7485 23.583 18.2908C21.2751 17.6081 18.8451 17.4354 16.4631 17.7847C16.2754 15.4287 15.8495 13.0976 15.1917 10.8266V10.8266Z"
                    fill="#009C06"
                />
                <path d="M51.1432 41.0642L47.9028 35.0146H46.3579V41.1017H43.1426V25.0981H49.4853C55.2126 25.0981 55.7652 33.1186 51.0553 34.4782L54.8232 41.0642H51.1432ZM46.3579 31.8713H49.1839C51.3693 31.8713 51.3693 28.2914 49.1839 28.2914H46.3579V31.8713Z" />
                <path d="M55.0863 41.0632V25.0981H63.0675V28.2912H58.2738V31.4842H62.4573V34.6772H58.2738V37.8702H63.3289V41.0632H55.0863Z" />
                <path d="M69.6449 41.4096L62.9633 25.0981H66.7453L68.8885 30.4147L70.0609 33.7219L71.2081 30.527L73.3764 25.0981H77.1584L70.4265 41.4096H69.6449Z" />
                <path d="M77.2159 41.0632V25.0981H80.2508V41.0632H77.2159Z" />
                <path d="M81.8859 41.0632V25.0981H89.8546V28.2912H85.0734V31.4842H89.2569V34.6772H85.0734V37.8702H90.1285V41.0632H81.8859Z" />
                <path d="M96.3773 41.4096L89.7239 25.0981H93.3738L96.6434 33.2601L99.8243 25.2604H99.9384L103.119 33.2601L106.389 25.0981H110.077L103.449 41.3223H102.866L99.9384 34.2585L96.9856 41.4096H96.3773Z" />
                <path d="M109.522 36.6887C110.857 37.6121 112.469 38.0524 114.089 37.9365C114.304 37.9432 114.518 37.9069 114.719 37.8298C114.92 37.7526 115.103 37.6362 115.258 37.4874C115.413 37.3386 115.537 37.1604 115.622 36.9632C115.707 36.766 115.751 36.5539 115.753 36.3393C115.678 35.4159 114.99 35.0166 114.089 34.7046C111.587 33.7812 109.347 32.5084 109.347 29.838C109.313 28.6052 109.769 27.4089 110.615 26.5093C111.46 25.6096 112.628 25.0794 113.864 25.0338C115.227 24.9755 116.587 25.2051 117.855 25.7076L117.454 28.7773C116.427 28.3095 115.306 28.0792 114.177 28.1035C113.949 28.1035 113.725 28.1517 113.518 28.2449C113.311 28.3382 113.126 28.4743 112.975 28.6442C112.825 28.8142 112.713 29.0141 112.646 29.2307C112.579 29.4473 112.559 29.6755 112.588 29.9004C112.675 30.7864 113.463 31.1482 114.377 31.4976C116.879 32.421 119.018 33.8436 119.018 36.4017C119.034 37.0412 118.919 37.6772 118.681 38.2714C118.444 38.8655 118.088 39.4056 117.636 39.8591C117.183 40.3126 116.644 40.6701 116.049 40.9102C115.455 41.1502 114.818 41.2678 114.177 41.2558C112.436 41.3053 110.712 40.9056 109.172 40.0953L109.522 36.6887Z" />
                <path d="M54.1059 22.9833L49.6984 14.6769H47.5767V22.9833H43.207V1.21826H51.8327C59.6375 1.21826 60.4079 12.151 53.9796 14.0109L59.0313 22.9833H54.1059ZM47.5767 10.4169H51.4412C54.4091 10.4169 54.4091 5.54111 51.4412 5.54111H47.5767V10.4169Z" />
                <path d="M67.4851 0.409668H67.9536L77.1838 23.0473H72.2458L71.3595 20.708H64.0664L63.2055 23.0473H58.2421L67.4851 0.409668ZM69.7262 16.5578L67.7383 10.8858L65.6744 16.5578H69.7262Z" />
                <path d="M78.5244 1.17944H87.1816C91.0559 1.17944 93.4915 3.95672 93.378 7.78961C93.4026 8.61337 93.2328 9.4314 92.8821 10.1779C92.5314 10.9244 92.0096 11.5784 91.3588 12.0875C92.0623 12.5753 92.6365 13.2257 93.0322 13.9831C93.4278 14.7404 93.633 15.582 93.6303 16.4356C93.6403 17.2876 93.4813 18.1332 93.1623 18.9239C92.8433 19.7145 92.3707 20.4347 91.7715 21.043C91.1724 21.6513 90.4585 22.1358 89.6708 22.4687C88.8832 22.8017 88.0373 22.9764 87.1816 22.983H78.5244V1.17944ZM82.9287 9.97625H86.803C87.0837 9.9737 87.3609 9.91361 87.6173 9.79971C87.8737 9.68581 88.1038 9.52055 88.2932 9.31422C88.4827 9.1079 88.6274 8.86493 88.7184 8.60047C88.8094 8.336 88.8447 8.05571 88.8222 7.77705C88.8468 7.49859 88.8127 7.21809 88.7223 6.95346C88.6319 6.68883 88.4871 6.44587 88.2971 6.24011C88.1071 6.03435 87.8761 5.8703 87.6188 5.75843C87.3616 5.64657 87.0837 5.58934 86.803 5.59041H82.9287V9.97625ZM82.9287 18.6851H86.803C87.094 18.6885 87.3827 18.6339 87.6521 18.5246C87.9216 18.4152 88.1664 18.2534 88.3722 18.0485C88.5779 17.8436 88.7405 17.5998 88.8503 17.3315C88.9601 17.0631 89.0149 16.7756 89.0115 16.4859C89.015 16.1979 88.96 15.9121 88.8498 15.6458C88.7396 15.3795 88.5764 15.1381 88.3701 14.9362C88.1638 14.7343 87.9186 14.576 87.6493 14.4709C87.38 14.3657 87.0921 14.3159 86.803 14.3244H82.9287V18.6851Z" />
                <path d="M95.8045 1.17944H104.462C108.349 1.17944 110.772 3.95672 110.671 7.78961C110.689 8.6125 110.517 9.42856 110.167 10.1741C109.816 10.9196 109.298 11.5745 108.652 12.0875C109.353 12.5759 109.926 13.2268 110.319 13.9842C110.713 14.7416 110.916 15.5829 110.91 16.4356C110.92 17.2876 110.761 18.1332 110.442 18.9239C110.123 19.7145 109.651 20.4347 109.052 21.043C108.453 21.6513 107.739 22.1358 106.951 22.4687C106.163 22.8017 105.317 22.9764 104.462 22.983H95.8045V1.17944ZM100.209 9.97625H104.096C104.377 9.9737 104.654 9.91361 104.91 9.79971C105.166 9.68581 105.397 9.52055 105.586 9.31422C105.775 9.1079 105.92 8.86493 106.011 8.60047C106.102 8.336 106.137 8.05571 106.115 7.77705C106.14 7.49859 106.106 7.21809 106.015 6.95346C105.925 6.68883 105.78 6.44587 105.59 6.24011C105.4 6.03435 105.169 5.8703 104.912 5.75843C104.654 5.64657 104.376 5.58934 104.096 5.59041H100.209V9.97625ZM100.209 18.6851H104.096C104.392 18.6905 104.685 18.6356 104.959 18.5238C105.233 18.412 105.481 18.2457 105.688 18.035C105.894 17.8243 106.056 17.5738 106.162 17.2988C106.268 17.0237 106.316 16.73 106.304 16.4356C106.308 16.1487 106.253 15.864 106.144 15.5985C106.034 15.3331 105.872 15.0923 105.667 14.8905C105.462 14.6888 105.219 14.5303 104.951 14.4243C104.683 14.3184 104.396 14.2674 104.108 14.2741H100.221L100.209 18.6851Z" />
                <path d="M113.085 22.9833V1.21826H117.288V22.9833H113.085Z" />
                <path d="M124.093 22.983V5.56639H118.653V1.24365H133.912V5.56639H128.472V22.983H124.093Z" />
                <path d="M135.025 17.0035C136.842 18.2706 139.035 18.8865 141.247 18.7508C141.542 18.7575 141.834 18.7056 142.108 18.5982C142.382 18.4908 142.632 18.33 142.843 18.1252C143.054 17.9205 143.222 17.6758 143.337 17.4055C143.453 17.1352 143.513 16.8447 143.514 16.5509C143.414 15.2938 142.469 14.7533 141.247 14.3259C137.834 13.0688 134.786 11.3466 134.786 7.68853C134.752 6.84527 134.887 6.00373 135.181 5.21275C135.476 4.42177 135.925 3.6971 136.503 3.0808C137.081 2.4645 137.775 1.96885 138.547 1.62259C139.318 1.27633 140.15 1.08637 140.995 1.06374C142.849 0.971697 144.701 1.28044 146.424 1.96883L145.882 6.18004C144.487 5.5348 142.961 5.22071 141.424 5.26237C141.114 5.25775 140.806 5.31998 140.522 5.44481C140.239 5.56964 139.985 5.75414 139.78 5.98568C139.574 6.21722 139.421 6.49035 139.331 6.78641C139.24 7.08247 139.215 7.39448 139.257 7.7011C139.383 8.95818 140.454 9.46101 141.701 9.88842C145.089 11.1455 147.998 13.094 147.998 16.5635C148.017 17.4348 147.86 18.301 147.535 19.11C147.21 19.9191 146.725 20.6544 146.109 21.2718C145.492 21.8893 144.758 22.3762 143.948 22.7033C143.139 23.0305 142.272 23.1912 141.398 23.1757C138.998 23.2599 136.618 22.7137 134.496 21.5918L135.025 17.0035Z" />
                <path d="M127.05 40.7796C127.05 40.8151 127.04 40.8503 127.021 40.8831C127.002 40.916 126.974 40.9458 126.94 40.9709C126.905 40.996 126.863 41.016 126.818 41.0296C126.772 41.0432 126.723 41.0502 126.674 41.0502C126.625 41.0502 126.576 41.0432 126.53 41.0296C126.485 41.016 126.443 40.996 126.409 40.9709C126.374 40.9458 126.346 40.916 126.327 40.8831C126.308 40.8503 126.299 40.8151 126.299 40.7796C126.299 40.7441 126.308 40.7089 126.327 40.6761C126.346 40.6432 126.374 40.6134 126.409 40.5883C126.443 40.5632 126.485 40.5432 126.53 40.5296C126.576 40.516 126.625 40.509 126.674 40.509C126.723 40.509 126.772 40.516 126.818 40.5296C126.863 40.5432 126.905 40.5632 126.94 40.5883C126.974 40.6134 127.002 40.6432 127.021 40.6761C127.04 40.7089 127.05 40.7441 127.05 40.7796V40.7796Z" />
                <path d="M123.594 37.6318C123.594 37.9441 123.729 38.2435 123.97 38.4642C124.211 38.685 124.538 38.809 124.879 38.809C125.22 38.809 125.547 38.685 125.788 38.4642C126.029 38.2435 126.164 37.9441 126.164 37.6318C126.166 37.3732 126.099 37.1179 125.97 36.8878L126.843 36.7878C126.958 37.057 127.02 37.3428 127.025 37.6318C127.047 37.9017 127.008 38.1728 126.911 38.4282C126.813 38.6836 126.659 38.9178 126.459 39.1162C126.258 39.3146 126.014 39.4728 125.743 39.5811C125.473 39.6893 125.18 39.7452 124.885 39.7452C124.59 39.7452 124.297 39.6893 124.026 39.5811C123.756 39.4728 123.512 39.3146 123.311 39.1162C123.11 38.9178 122.956 38.6836 122.859 38.4282C122.761 38.1728 122.723 37.9017 122.745 37.6318C122.741 37.3729 122.795 37.116 122.902 36.8767L123.775 36.9655C123.65 37.1683 123.587 37.3987 123.594 37.6318V37.6318Z" />
                <path d="M122.719 33.8634C122.719 33.3152 122.948 32.7894 123.356 32.4018C123.763 32.0142 124.315 31.7964 124.891 31.7964C125.467 31.7964 126.02 32.0142 126.427 32.4018C126.834 32.7894 127.063 33.3152 127.063 33.8634C127.063 34.4116 126.834 34.9374 126.427 35.325C126.02 35.7127 125.467 35.9304 124.891 35.9304C124.315 35.9304 123.763 35.7127 123.356 35.325C122.948 34.9374 122.719 34.4116 122.719 33.8634V33.8634ZM123.593 33.8634C123.624 34.172 123.775 34.4584 124.016 34.6668C124.258 34.8752 124.572 34.9907 124.897 34.9907C125.223 34.9907 125.537 34.8752 125.778 34.6668C126.019 34.4584 126.17 34.172 126.202 33.8634C126.17 33.5548 126.019 33.2684 125.778 33.06C125.537 32.8516 125.223 32.7361 124.897 32.7361C124.572 32.7361 124.258 32.8516 124.016 33.06C123.775 33.2684 123.624 33.5548 123.593 33.8634V33.8634Z" />
                <path d="M122.732 27.2734V27.0229H127.012V27.82H124.66L125.787 28.8333L124.66 29.8467H127.012V30.6437H122.732V30.3932L124.575 28.8333L122.732 27.2734Z" />
                <path
                    d="M15.1917 10.8266C14.556 8.67597 13.9203 6.27228 12.5217 5.00717C12.1744 4.67072 11.7288 4.45267 11.2489 4.38446C10.7691 4.31625 10.2799 4.4014 9.85183 4.62764C7.43616 6.01925 8.07184 10.0676 8.83468 12.8508C9.64725 15.4765 10.7116 18.0184 12.0132 20.4414C8.96182 23.3512 7.18185 27.526 6.92757 33.3455C7.79933 32.184 9.06369 31.3752 10.4875 31.0683C13.5389 30.6888 15.0646 33.219 16.8445 34.3576C17.0864 33.6194 17.3839 32.9005 17.7345 32.2069C18.3702 32.3334 19.0059 32.3334 19.5145 32.4599L18.7517 34.7371C18.6245 34.9901 18.3702 35.2431 18.7517 35.3696C19.1331 35.4961 19.0059 35.1166 19.1331 34.8636C19.5145 34.1045 19.7688 33.3455 20.0231 32.7129C20.6588 32.8394 21.2944 32.8394 21.803 32.9659C21.4216 34.1045 21.0402 35.1166 20.6588 36.1287C26.8886 37.6468 32.4828 34.7371 32.3556 28.7911C32.3556 24.9958 30.1943 22.7186 28.16 20.5679C30.3238 17.7027 32.1576 14.6049 33.627 11.3327C34.2473 10.1141 34.5944 8.77598 34.6442 7.41087C34.6687 7.02805 34.6088 6.64454 34.4686 6.28719C34.3285 5.92984 34.1115 5.6073 33.833 5.34215C33.5544 5.07701 33.2209 4.87567 32.8559 4.75225C32.4909 4.62882 32.1032 4.58629 31.7199 4.62764C29.94 4.75415 28.9229 6.27228 28.0329 7.53738C25.9986 10.5736 24.7272 14.7485 23.583 18.2908C21.2751 17.6081 18.8451 17.4354 16.4631 17.7847C16.2754 15.4287 15.8495 13.0976 15.1917 10.8266V10.8266Z"
                    fill="white"
                />
                <path
                    d="M11.4015 26.0732C12.735 26.8555 14.0686 27.6378 15.4021 28.3084C13.9473 30.3199 10.068 28.5207 11.4015 26.0732Z"
                    fill="#009C06"
                />
                <path
                    d="M28.8849 26.7148C30.0983 29.2664 25.9728 30.7085 24.7594 28.7117C26.2155 28.0461 27.4289 27.3805 28.8849 26.7148Z"
                    fill="#009C06"
                />
            </svg>
        </>
    );
}
