/**
 * Component to add the cookie consent to every page
 */
import classes from '@styles/common-scss/_cookieConsent.module.scss';
import { parseCookies } from 'nookies';
import React, { useEffect, useState } from 'react';

export default function Cookieconsent(): JSX.Element {
    const [bannerState, newBannerState] = useState('hidden');

    function handleClick() {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        document.cookie = `cookieconsent_status=dismiss;expires=${oneYearFromNow.toUTCString()};`;
        newBannerState('hidden');
    }
    useEffect(() => {
        // Fetch the cookie consent text from the API and set it in the cookie consent banner
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/cookie-text')
            .then((response) => response.json())
            .then((data) => {
                // If the fetched data contains text, set it as the innerHTML of the paragraph in the cookie consent banner
                if (data && data.text) {
                    document.getElementById('cookieConsent').querySelector('p').innerHTML = data.text;
                }
            })
            .catch((error) => {
                // Log an error message if there is an issue fetching the cookie text
                // console.log('Error fetching cookie text:', error);
            });

        // Check the current cookies to determine if the cookie consent banner should be shown
        const cookies = parseCookies();
        // If the cookie consent status is not 'dismiss', show the banner
        if (cookies.cookieconsent_status !== 'dismiss') {
            newBannerState('shown');
        }
    }, []);

    return (
        <div className={`${classes.cookieConsentBanner} ${bannerState}`} id="cookieConsent">
            <p></p>
            <div
                className={classes.greenButton}
                onClick={() => handleClick()}
                onKeyDown={() => handleClick()}
                data-event-category="User Action"
                data-event-action="Cookie-Confirm"
                data-event-label="PAGE"
                role="button"
                tabIndex={0}
                aria-label="accept button">
                OK
            </div>
        </div>
    );
}
