/**
 * The rr blog icon
 * @returns SVG
 */
export default function svgBack(): JSX.Element {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M19.8125 23.625L12.1875 16L19.8125 8.375"
                    stroke="white"
                    strokeWidth="3.05"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
