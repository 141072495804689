import styles from '@styles/common-scss/_newsLetterForm.module.scss';
import React, { useEffect, useState } from 'react';

export default function NewsLetterForm(): JSX.Element {
    const [email, setEmail] = useState('');
    const [notice, setNotice] = useState(['', '']);
    const checkEmail = () => {
        if (email) {
            handleSubmit();
            //We removed the Email Checker as of PAYS-2075, Email Verification will be done in the backend from now on
            // fetch('https://api-v4.bulkemailchecker.com/?key=' + process.env.NEXT_PUBLIC_EMAIL_KEY + '&email=' + email)
            //     .then((data) => {
            //         return data.json();
            //     })
            //     .then((jsonDATA) => {
            //         if (jsonDATA.status === 'passed') {
            //             handleSubmit();
            //         } else {
            //             setNotice(['Please enter a valid e-mail.', '#fff']);
            //         }
            //     })
            //     .catch((err) => {
            //         setNotice([`Something went wrong. Please try again later.`, '#fff']);
            //     });
        } else {
            setNotice(['Email cannot be empty.', '#fff']);
        }
    };

    const handleSubmit = () => {
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ email: email })
        };
        fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/newsletter', options)
            .then((data) => {
                return data.json();
            })
            .then((jsonData) => {
                if (jsonData.error || jsonData.message) {
                    setNotice(['Email successfully subscribed', '#009C06']);
                } else {
                    setNotice([`Something went wrong. Please try again later.`, '#fff']);
                }
            })
            .catch((err) => {
                setNotice([`Something went wrong. Please try again later.`, '#fff']);
            });
    };

    useEffect(() => {
        if (email.length > 0) {
            setNotice(['', '']);
        }
    }, [email]);
    return (
        <>
            <section className={`${styles.newsLetterSection}`}>
                <div className={`${styles.formContainer}`}>
                    <h2>GET NEWSLETTER &amp; DISCOUNTS</h2>
                    <div className={styles.form}>
                        <input
                            aria-label="Subscribe to newsletter"
                            className="input"
                            name="newsletter_subscription[email]"
                            placeholder={'Your E-mail'}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            className={`${styles.submit} button`}
                            onClick={() => {
                                checkEmail();
                            }}>
                            SUBSCRIBE
                        </button>
                    </div>
                    {notice[0] ? (
                        <p className={styles.notice} style={{ color: notice[1] }}>
                            {notice[0]}
                        </p>
                    ) : (
                        ''
                    )}
                </div>
            </section>
        </>
    );
}
