// /**
//  * Component for loging clicks on thumbnails
//  */

import { useEffect } from 'react';
export default function Loger(): null {
    useEffect(function mount() {
        function onClick(Element) {
            let thumbID = Element.target.dataset.thumbid;
            let site = undefined;
            let click = undefined;
            if (thumbID) {
                if (typeof click === 'undefined') click = 'cl';
                if (typeof site === 'undefined') site = 'p';
                if (typeof thumbID === 'undefined') thumbID = 0;
                try {
                    // const lpath = process.env.NEXT_PUBLIC_RR9_DOMAIN + '/scripts/pthumb/' + click + '/' + site + '/' + thumbID;
                    const lpath = process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/clthumb/' + thumbID;
                    fetch(lpath, {
                        method: 'POST'
                    }).catch(function (e) {
                        // do nothing
                    });
                } catch {
                    // Do nothing
                }
            }
        }
        window.addEventListener('click', onClick);

        return function unMount() {
            window.removeEventListener('click', onClick);
        };
    });
    return null;
}
