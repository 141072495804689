const paginationLimit = 24;
const paginationLimit48 = 48;
const placeHolderCard = 'https://assets.rabbitsreviews.com/images/rabbitslogo.png';
const dealPages = [
    'april-fool-discounts',
    'mardi-gras-deals',
    'oktoberfest-deals',
    'anniversary-porn-deals',
    'black-friday-porn-deals',
    'christmas-porn-deals',
    'new-years-porn-deals',
    'valentines-day-porn-deals',
    'st-patricks-porn-deals',
    'july-4th-porn-deals',
    'labor-day-porn-deals',
    'thanksgiving-porn-deals',
    'halloween-porn-deals',
    'easter-porn-deals',
    'cyber-monday-porn-deals',
    'exclusive-vip-deals'
];

export { dealPages, paginationLimit, paginationLimit48, placeHolderCard };
