/* eslint-disable jsx-a11y/media-has-caption */
import Link from 'next/link';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';

import styles from '../../../styles/scss/common-scss/_promoVideo.module.scss';
import SvgX from '../../svgs/svgBlackX';

export default function PromoVideo(): JSX.Element {
    const [videoToggle, setVideoToggle] = useState(false);
    const [bannerVideoUrl, setBannerVideoUrl] = useState('');
    const [destinationURL, setDestinationURL] = useState('');
    useEffect(() => {
        // Function to fetch the API endpoint
        const fetchBannerVideoUrl = async () => {
            try {
                const response = await fetch(process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/snippet/banner-video');
                if (!response.ok) {
                    throw new Error('Failed to fetch banner video URL');
                }

                const data = await response.json();
                if (data && data.text && (data.text != '-' || data.text.indexOf(',') === -1)) {
                    const urls = data.text.split(',');

                    if (isStringInURLFormat(urls[0])) {
                        // Set the state with the fetched URL
                        setBannerVideoUrl(urls[0]);
                        setDestinationURL(urls[1]);
                    }
                }

                // Set the state with the fetched URL
            } catch (error) {
                setBannerVideoUrl('');
            }
        };
        const cookies = parseCookies();
        //If no cookies are found, the promo banner wasn't closed
        if (!cookies.promo) {
            setVideoToggle(true);
            fetchBannerVideoUrl();
        }
    }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

    const isStringInURLFormat = (str) => {
        // Regular expression pattern for a basic URL structure
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

        // Test if the string matches the regular expression
        return urlPattern.test(str);
    };
    const closeAd = () => {
        // Set the "promo" cookie to expire in 24 hours
        setCookie(null, 'promo', 'true', {
            maxAge: 86400,
            path: '/'
        });
        setVideoToggle(false);
    };

    return videoToggle && bannerVideoUrl ? (
        <div className={styles.promoVideo}>
            <button className={styles.promoVideoContainer} onClick={() => closeAd()}>
                <div className={styles.closeIcon}>
                    <a
                        className={styles.closeButton}
                        data-event-category="User Action"
                        data-event-action="video-banner-closed"
                        data-event-label="video-banner-closed">
                        <SvgX color={'white'} />{' '}
                    </a>
                </div>
                <Link href={destinationURL}>
                    <a
                        data-event-category="User Action"
                        data-event-action="video-banner-click"
                        data-event-label="video-banner-click"
                        target="_blank">
                        <div className={styles.promoContainer}>
                            <video muted autoPlay loop playsInline className={styles.video}>
                                <source src={bannerVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </a>
                </Link>
            </button>
        </div>
    ) : (
        <></>
    );
}
