/**
 * Simple component for grouping the Footer
 * Helps to simplify the layout
 */
import NewsLetter from '@components/partials/common/newsLetter';
import Link from 'next/link';

import classes from '../../../styles/scss/common-scss/_footer.module.scss';
import Blog from '../../svgs/svgBlog';
import Instagram from '../../svgs/svgInstagram';
import Twitter from '../../svgs/svgTwitter';

export default function Footer(): JSX.Element {
    return (
        <footer className={classes.footer}>
            <div className={classes.footerDIV}>
                <Link href={'/about-rabbits-reviews/who-we-are'}>
                    <a title="About us" className={classes.footerLinks}>
                        ABOUT US
                    </a>
                </Link>
                <a title="Contact us" className={classes.footerLinks} href="/customer-support/contact-us">
                    CONTACT US
                </a>
                <div className={classes.footerSocialMedia}>
                    <a
                        href="https://twitter.com/RabbitsRev"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Follow us on Twitter">
                        <Twitter />
                    </a>
                    <a
                        href="https://www.instagram.com/rabbitsreviews/"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Follow us on instagram">
                        <Instagram />
                    </a>
                    <a
                        href={process.env.NEXT_PUBLIC_APP_DOMAIN + '/hareald/'}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Read more on the Hareald">
                        <Blog />
                    </a>
                </div>
                <div className={classes.newsletterDesktop}>
                    <NewsLetter />
                </div>
            </div>
            <div className={classes.subFooter}>
                <ul>
                    <li>
                        <Link href="/legal#FAQ">
                            <a title="Frequently Asked Questions">FAQ</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal#TOS">
                            <a title="Terms of Service">TOS</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal#Cookie">
                            <a title="Cookie Policy">Cookies</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal#USC18">
                            <a title="2257">2257</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal#DMCA">
                            <a title="DMCA">DMCA</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal#privacy">
                            <a title="Privacy Policy">Privacy Policy</a>
                        </Link>
                    </li>
                </ul>
                <p className={classes.footerCopyright}>
                    All right reserved © 2003-{new Date().getFullYear()} RabbitsReviews.com. <br /> We may earn a small
                    commission if you make a purchase through our links. It is how you support this site-thank you!
                    <span className={classes.break}>
                        <br />
                    </span>{' '}
                    Design and Reviews are copyrighted.
                </p>
            </div>
        </footer>
    );
}
