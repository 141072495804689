import styles from '@styles/common-scss/_notificationConsent.module.scss';
import { parseCookies } from 'nookies';
import React, { useEffect, useState } from 'react';

import { MyContext } from '../../../../context/provider';

interface NotificationBarProps {
    isDisplayNotification: boolean;
    setIsDisplayNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationConsent: React.FC<NotificationBarProps> = ({ isDisplayNotification, setIsDisplayNotification }) => {
    const { notificationBarData } = MyContext();
    const cookies = parseCookies(); // Parse cookies for EXID
    const [cookieClient, setCookieClient] = useState<boolean>(cookies.notificationconsent_status === 'true');

    useEffect(() => {
        setIsDisplayNotification(cookieClient);
    }, [cookieClient]);

    const handleClick = () => {
        setIsDisplayNotification(false);
        const sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

        document.cookie = `notificationconsent_status=false; expires=${sevenDaysFromNow.toUTCString()}; path=/;`;
    };

    if (!isDisplayNotification || notificationBarData == '') return null;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div dangerouslySetInnerHTML={{ __html: notificationBarData }}></div>
                <a data-event-label="Notification Bar Closed">
                    <button className={styles.button} onClick={handleClick}>
                        <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.25 8.25006L8.75 24.7501"
                                strokeWidth="3.08333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.75 8.25006L25.25 24.7501"
                                strokeWidth="3.08333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default NotificationConsent;
