import styles from '@styles/common-scss/navbar/hamburger-menu/_burger.module.scss';
import React, { Dispatch, SetStateAction } from 'react';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    open: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
};

const Burger: React.FC<Props> = ({ open, setOpen }) => {
    return (
        <button
            className={`${styles.button} ${open ? styles.open : ''}`}
            onClick={() => setOpen(!open)}
            aria-label="burger button"
            id="common-hamburger-closed">
            <div className={styles.bar} />
            <div className={styles.bar} />
            <div className={styles.bar} />
        </button>
    );
};

export default Burger;
