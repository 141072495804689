import Back from '@components/svgs/svgBack';
import { useRouter } from 'next/router';

const GoBackButton = () => {
    const router = useRouter();
    const handleBackButtonClick = () => {
        router.back();
    };

    return (
        <>
            <style>
                {`
            .btnContainer {
                display: flex;
                position: fixed;
                bottom: 10px;
                left: 10px;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 100px;
                background: rgba(41, 41, 41, 0.60);
                box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.30);
                cursor: pointer;
            }
            @media (min-width: 1025px) {
                .btnContainer {
                    display: none
                }
            }
            `}
            </style>
            <button type="button" className="btnContainer" onClick={handleBackButtonClick}>
                <Back />
            </button>
        </>
    );
};

export default GoBackButton;
