import styles from '@styles/common-scss/navbar/_navbar.module.scss';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect } from 'react';

import { holidayProps } from '../../../../lib/types/holidayProps';
import SvgNavLogo from '../../../svgs/svgNavLogoBlack';
import Burger from './navbar-burger/burger/burger';
import Search from './search';

type Props = {
    holiday: holidayProps;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// Function to map holiday names to their corresponding file names
const findHolidayFileLocation = (holidayName: string): string => {
    // Object mapping holiday names to their file names
    const holidayMap = {
        'Christmas Deals': 'Christmas',
        'CYBER MONDAY DEALS': 'CyberMonday',
        'Halloween Deals': 'Halloween',
        'Black Friday': 'BlackFriday',
        'Valentines Day': 'Valentine',
        'THANKSGIVING DEALS': 'Thanksgiving',
        'ST PATRICKS DAY': 'StPatrick',
        'New Year Deals': 'NewYear',
        'July 4th porn Deals': 'July4th',
        'EASTER DEALS': 'Easter',
        'Labor Day': 'LaborDay'
    };
    return holidayMap[holidayName] || '';
};

// Function to dynamically import and render the holiday logo
const getHolidayLogo = (holiday) => {
    // Get the file name for the holiday
    const fileURIToImport = findHolidayFileLocation(holiday.name);
    // Dynamically import the SVG component based on the file name
    const DynamicImport = dynamic(() => import(`../../../svgs/holidayLogoSvgs/${fileURIToImport}`));
    // Render the dynamically imported component
    return <DynamicImport />;
};

export default function Navbar({ holiday, open, setOpen }: Props): JSX.Element {
    // Delete user when logged. We removed the login but check if user is logged and logout them.
    useEffect(() => {
        localStorage.removeItem('apiToken');
    }, []);

    return (
        <nav className={styles.wrapper} id="#top">
            <div className={styles.content}>
                <div className={styles.left}>
                    <div className={`${styles.hamburgerMenu} ${holiday?.name ? styles.holidayHamburger : ''}`}>
                        <Burger open={open} setOpen={setOpen} />
                    </div>

                    <Link href="/">
                        <a
                            title="Honest porn reviews"
                            className={`${styles.logoMobile} ${holiday?.name ? styles.holidayLogo : ''}`}
                            data-event-category="User Action"
                            data-event-action="Header"
                            data-event-label="Logo">
                            {holiday?.name ? getHolidayLogo(holiday) : <SvgNavLogo />}
                        </a>
                    </Link>
                </div>
                <div className={styles.toolWrapper}>
                    <div className={styles.tool}>
                        <Search />
                    </div>
                </div>
            </div>
        </nav>
    );
}
