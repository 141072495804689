import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';

export default function MIDcookie(): JSX.Element {
    const router = useRouter();
    if (process.browser) {
        const query = router.query;
        const midQuery = query.mid;

        const cookies = parseCookies();
        let mid: string;

        mid = '1';

        if (cookies.MemberID) {
            mid = String(cookies.MemberID);
        }
        if (typeof midQuery !== 'undefined') {
            mid = midQuery.toString();
        }

        const mid_int = parseInt(mid, 10);

        sessionStorage.setItem('memberID', String(mid));

        if (typeof midQuery !== 'undefined') {
            if (window.document !== undefined) {
                document.cookie = 'MemberID=' + mid + ';' + 30 * 24 * 60 * 60 + ';path=/;SameSite=None; Secure';
            }
        }
    }

    return <></>;
}
