/**
 * The rr blog icon
 * @returns SVG
 */
export default function svgBlog(): JSX.Element {
    return (
        <>
            <svg height="36" viewBox="0 0 39.685 36" width="39.685" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-1200.54 -525.025)">
                    <path d="m1223.684 535.675a1.439 1.439 0 0 0 -1.259.594 3.078 3.078 0 0 0 -.42 1.775v3.082a3.05 3.05 0 0 0 .427 1.782 1.632 1.632 0 0 0 2.519 0 3.009 3.009 0 0 0 .435-1.782v-3.082a3 3 0 0 0 -.435-1.771 1.47 1.47 0 0 0 -1.267-.598z" />
                    <path d="m1209.488 540.233h-1.649v3.112h1.507a1.279 1.279 0 0 0 1.008-.375 1.636 1.636 0 0 0 .334-1.117 2.128 2.128 0 0 0 -.285-1.2 1.026 1.026 0 0 0 -.915-.42z" />
                    <path d="m1210.017 538.362a1.459 1.459 0 0 0 .348-1.049 1.525 1.525 0 0 0 -.36-1.123 1.483 1.483 0 0 0 -1.087-.357h-1.079v2.909h1.177a1.367 1.367 0 0 0 1.001-.38z" />
                    <path d="m1238.742 525.025h-36.718a1.489 1.489 0 0 0 -1.484 1.484v25.908a1.488 1.488 0 0 0 1.484 1.483h22.367a2.843 2.843 0 0 1 2.223 1.286l3.053 5.309a.781.781 0 0 0 1.479 0l3.052-5.309a2.845 2.845 0 0 1 2.223-1.286h2.321a1.487 1.487 0 0 0 1.483-1.483v-25.908a1.488 1.488 0 0 0 -1.483-1.484zm-26.829 19.212a3.7 3.7 0 0 1 -2.567.809h-3.646v-10.915h3.216a4.15 4.15 0 0 1 2.636.75 2.674 2.674 0 0 1 .948 2.234 2.735 2.735 0 0 1 -.345 1.383 2.128 2.128 0 0 1 -1.035.9 1.94 1.94 0 0 1 1.275.877 2.974 2.974 0 0 1 .412 1.582 3.038 3.038 0 0 1 -.894 2.38zm8.047.809h-6.125v-10.915h2.136v9.214h3.989zm7.548-3.92a4.139 4.139 0 0 1 -1.042 3 4.094 4.094 0 0 1 -5.541 0 4.157 4.157 0 0 1 -1.034-3v-3.059a4.19 4.19 0 0 1 1.034-3 3.625 3.625 0 0 1 2.759-1.09 3.673 3.673 0 0 1 2.774 1.09 4.151 4.151 0 0 1 1.05 3zm8.23 2.3a3.926 3.926 0 0 1 -1.327 1.241 4.27 4.27 0 0 1 -2.242.536 3.612 3.612 0 0 1 -3.876-3.951v-3.329a3.967 3.967 0 0 1 1.024-2.916 3.639 3.639 0 0 1 2.71-1.034 3.746 3.746 0 0 1 2.721.887 3.514 3.514 0 0 1 .945 2.542l-.015.045h-2a2.038 2.038 0 0 0 -.409-1.348 1.518 1.518 0 0 0 -1.18-.425 1.48 1.48 0 0 0 -1.222.552 2.651 2.651 0 0 0 -.443 1.677v3.347a2.683 2.683 0 0 0 .446 1.7 1.577 1.577 0 0 0 1.3.551 1.973 1.973 0 0 0 .941-.172 1.345 1.345 0 0 0 .5-.51v-1.957h-1.44v-1.5h3.569z" />
                </g>
            </svg>
        </>
    );
}
