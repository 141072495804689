import Base from '@components/base';
import Navbar from '@components/partials/common/navbar/navbar';
import Menu from '@components/partials/common/navbar/navbar-burger/menu/menu';
import NewsLetter from '@components/partials/common/newsLetter';
import PromoVideo from '@components/partials/common/promoVideo';
import styles from '@styles/common-scss/_layout.module.scss';
import React, { useEffect, useRef, useState } from 'react';

import { MyContext } from '../context/provider';
import { holidayProps } from '../lib/types/holidayProps';
import NotificationConsent from './partials/common/modal/notificationConsent';
import GoBackButton from './partials/common/navigation/goBack';

type Props = {
    holiday: holidayProps;
    children: React.ReactNode;
};

export default function Layout({ holiday, children }: Props): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const { notificationBarData, notificationBarState } = MyContext();

    const [isDisplayNotification, setIsDisplayNotification] = useState<boolean>(notificationBarState);
    const menuRef = useRef<HTMLDivElement>(null);

    return (
        <Base>
            <div className={styles.wrapper}>
                <NotificationConsent
                    setIsDisplayNotification={setIsDisplayNotification}
                    isDisplayNotification={isDisplayNotification}
                />
                <div
                    id="layout"
                    className={`${styles.container} ${isDisplayNotification == true && notificationBarData != '' ? styles.my90 : styles.default}`}>
                    <div ref={menuRef}>
                        <Navbar holiday={holiday} open={open} setOpen={setOpen} />
                        <div className={`${styles.leftContainer} ${open ? styles.openMobile : styles.closedMobile}`}>
                            <Menu setOpen={setOpen} menuRef={menuRef} />
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        {children}
                        <div
                            className={
                                open ? `${styles.transparencyDiv} ${styles.transparencySheen}` : `${styles.transparencyDiv}`
                            }></div>
                        <GoBackButton />
                    </div>
                </div>
            </div>
            <div className={styles.newsletterMobileOnly}>
                <NewsLetter />
            </div>
            <PromoVideo />
        </Base>
    );
}
