export default function svgBlackX({ color }: { color?: string }): JSX.Element {
    return (
        <>
            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6.00005L6 18" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6.00005L18 18" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
